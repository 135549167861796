import { numberWithCommas } from "../../../common";
import useDetails from "../../../hooks/useDetails";
import StudentInfo from "./StudentInfo";
import "./Style.css";

const ApplicantPayment = ({
  loading,
  btnText,
  handlePayment,
  admissionFee,
}) => {
  const { data } = useDetails();
  return (
    <>
    {data && 
    
    <div>
      <div class="card p-5" style={{ padding: "10px" }}>
        <StudentInfo data={data}/>
        <div class="pricing p-3 rounded mt-4 mb-4 d-flex justify-content-between">
          <div class="images d-flex flex-row align-items-center">
            <div class="d-flex flex-column ml-4">
              <span class="business">Session</span>
              <span class="plan">{admissionFee?.session}</span>
            </div>
          </div>
          <div class="d-flex flex-row align-items-center">
            <sup class="dollar font-weight-bold">₦</sup>
            <span class="amount ml-1 mr-1">
              {admissionFee && numberWithCommas(admissionFee?.amount)}
            </span>
          </div>
        </div>
        <div class="mt-3">
          <button
            class="btn btn-primary btn-block btn-raised waves-effect payment-button"
            onClick={handlePayment}
            disabled={loading}
          >
            {btnText} <i class="fa fa-long-arrow-right"></i>
          </button>
        </div>
      </div>
    </div>
    }
  </>
  );
};

export default ApplicantPayment;
