import React, { useEffect, useState } from "react";
import QRCode from "react-qr-code";
import { useLocation, useNavigate } from "react-router-dom";
import logo from "../../assets/images/coewarri.png";
import logo2 from "../../assets/images/IAUE.png";
import { getCurrentUser } from "../../components/services/auth/authService";
import {
  GetApplicantDetails,
  GetProspectiveDetails,
} from "../../api/application";
import moment from "moment";
import useDetails from "../../hooks/useDetails";
import useProspective from "../../hooks/useProspective";
import useProspectiveInfo from "../../hooks/useProspectiveInfo";
import avatar from "../../assets/images/user.png";

function ApprovedInvoice() {
  const [details, setDetails] = useState([]);
  const [student, setStudent] = useState();
  const [passport, setPassport] = useState();
  const [reference, setReference] = useState();
  const [amount, setAmount] = useState();
  const [createdAt, setCreatedAt] = useState();
  const [paymentName, setPaymentName] = useState();
  const [general, setGeneral] = useState();
  const [courseDetails, setCourseDetails] = useState();
  const navigate = useNavigate();
  const { data: dashboard } = useProspective();
  const { data } = useDetails();

  const { state } = useLocation();
  const user = getCurrentUser();

  const printPaymentReceipt = () => {
    window.print();
  };
  const onImageError = (e) => {
    e.target.src = avatar;
  };

  const getDetails = async () => {
    try {
      const { data } = await GetProspectiveDetails(user.admission_id);
      console.log(data, "dataaaa");
      setPassport(data.photo);
      // setStage(data.stage);
      setDetails(data);
      setCourseDetails(data.admin_response[0].approved_program);
    } catch (error) {}
  };

  useEffect(() => {
    //   getPassport();
    getDetails();
    console.log(state);
    setGeneral(state.invoice);
    setPaymentName(state?.feesName);
    setStudent(state?.invoice?.application);
    setReference(state?.invoice?.paystack_ref);
    setAmount(state?.invoice?.amount_paid);
    setCreatedAt(state?.invoice?.generated_date);
    setPassport(state?.invoice?.application?.photo);
  }, []);

  return (
    <>
      <div className="print-button">
        <button onClick={() => navigate(-1)} className="btn-danger">
          Go back
        </button>
        <button className="btn-primary" onClick={printPaymentReceipt}>
          Print
        </button>
      </div>
      {general && (
        <div className="receipt">
          <div className="contents">
            <div className="receipt-header">
              <div className="school-title">
                <img src={logo} alt="DSP" className="logo" />
                <h3 className="name">College of Education, Warri</h3>
              </div>
              <h6 className="text-center">In affiliation with</h6>
              <div className="school-title">
                <img src={logo2} alt="DSP" className="logo" />
                <h3 className="name">
                  Ignatius Ajuru University of Education, Port Harcourt
                </h3>
              </div>
              <hr className="border border-dark" />
              <h4 className="subheader">
                {paymentName} Payment Slip <span>{reference}</span>
              </h4>
            </div>
            <div className="receipt-body">
              <h4 style={{ background: "#0c0f2e" }} className="subtitle">
                Base Data
              </h4>
              <div className="body-header">
                <div className="passport">
                  <img src={passport} alt="Passport" onError={onImageError} />
                </div>
                <div className="user-details">
                  <div>
                    <h3 className="user-details-option">Name:</h3>
                    <h3 className="user-details-info">
                      {details
                        ? details?.surname + " " + details?.othername
                        : ""}
                    </h3>
                  </div>
                  <div>
                    <h3 className="user-details-option">Application Number:</h3>
                    <h3 className="user-details-info">{details?.username}</h3>
                  </div>
                  <div>
                    <h3 className="user-details-option">Email:</h3>
                    <h3 className="user-details-info">{details?.email}</h3>
                  </div>
                  <div>
                    <h3 className="user-details-option">Department:</h3>
                    <h3 className="user-details-info">
                      {courseDetails?.department}
                    </h3>
                  </div>
                  <div>
                    <h3 className="user-details-option">Faculty:</h3>
                    <h3 className="user-details-info">
                      {courseDetails?.faculty_name}
                    </h3>
                  </div>
                  <div>
                    <h3 className="user-details-option">Entry Mode:</h3>
                    <h3 className="user-details-info">{details?.entryMode}</h3>
                  </div>
                  <div>
                    <h3 className="user-details-option">Study Mode:</h3>
                    <h3 className="user-details-info">{details?.studyMode}</h3>
                  </div>
                  <div>
                    <h3 className="user-details-option">Session:</h3>
                    <h3 className="user-details-info">
                      {details?.entrySession}
                    </h3>
                  </div>
                </div>
                <div className="qr-code">
                  {data && <QRCode value={reference} size={90} />}
                </div>
              </div>
              <h4 style={{ background: "#0c0f2e" }} className="subtitle">
                Payment Data
              </h4>

              <div className="body-info">
                <div>
                  <h3 className="user-details-option">Amount Paid:</h3>
                  <h3 className="user-details-info">₦{amount}</h3>
                </div>
                {paymentName.toLowerCase().includes("certificate fee") &&
                <h5>
                  a) Convocation/Certificate fee - ₦60,000<br/>
                  b) Certificate folder & ID-Card - ₦5,500<br/>
                  c) Graduate Sch. Association -5,000<br/>
                  d) Alumni fee - ₦5,000
                </h5>
                 }
                <div>
                  <h3 className="user-details-option">Date of Payment:</h3>
                  <h3 className="user-details-info">
                    {moment(createdAt).format("DD/MM/YYYY hh:mm")}
                  </h3>
                </div>
                <div>
                  <h3 className="user-details-option">Payment Description:</h3>
                  <h3 className="user-details-info">{paymentName} Payment</h3>
                </div>
                <div>
                  <h3 className="user-details-option">Payment Reference:</h3>
                  <h3 className="user-details-info">{reference}</h3>
                </div>
                <div>
                  <h3 className="user-details-option">Payment Gateway:</h3>
                  <h3 className="user-details-info">PayStack</h3>
                </div>
              </div>
            </div>
            <div className="receipt-footer">
              <p>Date: {moment(student?.update).format("DD/MM/YYYY hh:mm")}</p>
              <p>
                {reference} - Online Payment Slip p6389714479930 - Page 1 of 1
              </p>
            </div>
          </div>
        </div>
      )}
      {!general && (
        <center>
          <div
            class="preloader pl-size-xs"
            style={{ marginRight: "5px", marginTop: "100px" }}
          >
            <div class="spinner-layer pl-red-grey">
              <div class="circle-clipper left">
                <div class="circle"></div>
              </div>
              <div class="circle-clipper right">
                <div class="circle"></div>
              </div>
            </div>
          </div>
        </center>
      )}
    </>
  );
}

export default ApprovedInvoice;
