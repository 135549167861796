import React, { UseEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import api from "../../api/api";
import Footer from "../../components/Footer";
import Logo from "../../components/Logo";
import ReactLoading from "react-loading";
import NavHeader from "./components/NavHeader";
import SliderCarousel from "./components/SliderCarousel";
import NewsMarquee from "./components/NewsMarquee";
import { currentSession } from "../../utils/appParams";

const ApplicationRegister = () => {
  const [surname, setSurname] = useState("");
  const [othername, setOthername] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPass, setConfirmPass] = useState("");
  const [programmeType, setProgrammeType] = useState("");
  const [entryMode, setEntryMode] = useState("");
  const [studyMode, setStudyMode] = useState("");
  const [loading, setLoading] = useState(false);
  const [underGradDegree, setUnderGradDegree] = useState("");
  const [underGradStart, setUnderGradStart] = useState("");
  const [underGradEnd, setUnderGradEnd] = useState("");
  const [classDegree, setClassDegree] = useState("");
  const [schoolName, setSchoolName] = useState("");

  const codee = phone.slice(0, 3).toString();

  const navi = useNavigate();

  const errorProp = {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  };

  const warnProp = {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  };

  const successProp = {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  };

  const registerApplicant = async (e) => {
    e.preventDefault();
    if (password !== confirmPass) {
      return toast.warning("Passwords do not match", warnProp);
    }
    try {
      setLoading(true);
      let res = await api.post("/admissions/apply", {
        surname,
        othername,
        email,
        password,
        entryMode,
        studyMode,
        programmeType,
        undergrad_course: underGradDegree,
        undergrad_year_of_entry: underGradStart,
        undergrad_year_of_grad: underGradEnd,
        class_of_degree: classDegree,
        school_name: schoolName,
      });
      console.log("Result", res);
      let id = res.data.data.application_no;
      setLoading(false);
      toast.success("Account Created", successProp);
      navi("/apply/account-created", { replace: true, state: { id } });
    } catch (error) {
      toast.error(error.response.data.message, errorProp);
      setLoading(false);
    }
  };

  return (
    <>
      <NavHeader />
      <div class="mt-5" style={{ minHeight: "120vh", paddingTop: "1rem" }}>
        <div class="container">
          <div class="row vh-100 d-flex justify-content-center">
            <div class="col-8 align-self-center">
              <div class="auth-page">
                <div class="card auth-card shadow-lg">
                  <div class="card-body">
                    <div class="px-3">
                      <div class="text-center auth-logo-text">
                        <h4 class="mt-0 mb-3 mt-5">
                          Postgraduate Application ({currentSession})
                        </h4>
                      </div>

                      <form
                        class="form-horizontal auth-form my-4"
                        onSubmit={registerApplicant}
                      >
                        <div class="form-group">
                          <label for="username">Surname</label>
                          <div class="input-group mb-3">
                            <span class="auth-form-icon">
                              <i class="dripicons-user"></i>
                            </span>
                            <input
                              type="text"
                              class="form-control"
                              name="surname"
                              placeholder="Enter surname"
                              value={surname}
                              onChange={(e) => setSurname(e.target.value)}
                              required
                            />
                          </div>
                        </div>
                        <div class="form-group">
                          <label for="username">Othername</label>
                          <div class="input-group mb-3">
                            <span class="auth-form-icon">
                              <i class="dripicons-user"></i>
                            </span>
                            <input
                              type="text"
                              class="form-control"
                              name="othername"
                              id="username"
                              placeholder="Enter othername"
                              value={othername}
                              onChange={(e) => setOthername(e.target.value)}
                              required
                            />
                          </div>
                        </div>

                        <div class="form-group">
                          <label for="useremail">Email</label>
                          <div class="input-group mb-3">
                            <span class="auth-form-icon">
                              <i class="dripicons-mail"></i>
                            </span>
                            <input
                              type="email"
                              class="form-control"
                              name="useremail"
                              id="useremail"
                              placeholder="Enter Email"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                              required
                            />
                          </div>
                        </div>

                        <div class="form-group">
                          <label for="mo_number">Programme Type</label>
                          <div class="input-group mb-3">
                            <span class="auth-form-icon">
                              <i class="dripicons-school"></i>
                            </span>
                            <select
                              class="form-control"
                              value={programmeType}
                              onChange={(e) => setProgrammeType(e.target.value)}
                            >
                              <option>--Select--</option>
                              <option value="PGDE">
                                Postgraduate Diploma (PGDE)
                              </option>
                              <option value="M.Ed">
                                Master of Education (M.Ed)
                              </option>
                            </select>
                          </div>
                        </div>
                        <div class="form-group">
                          <label for="mo_number">Entry Mode</label>
                          <div class="input-group mb-3">
                            <span class="auth-form-icon">
                              <i class="dripicons-school"></i>
                            </span>
                            <select
                              class="form-control"
                              value={entryMode}
                              onChange={(e) => setEntryMode(e.target.value)}
                            >
                              <option>--Select--</option>
                              <option value="PG Diploma">PG Diploma</option>
                              <option value="Masters">Masters</option>
                              <option value="Bachelor Degree">
                                Bachelor Degree
                              </option>
                              <option value="HND">HND</option>
                            </select>
                          </div>
                        </div>
                        <div class="form-group">
                          <label for="mo_number">Study Mode</label>
                          <div class="input-group mb-3">
                            <span class="auth-form-icon">
                              <i class="dripicons-"></i>
                            </span>
                            <select
                              class="form-control"
                              value={studyMode}
                              onChange={(e) => setStudyMode(e.target.value)}
                            >
                              <option>--Select--</option>
                              <option value="FT">Full Time</option>
                              <option value="PT">Part Time</option>
                            </select>
                          </div>
                        </div>
                        <div class="form-group">
                          <label for="username">Undergrad Course</label>
                          <div class="input-group mb-3">
                            <span class="auth-form-icon">
                              <i class="dripicons-user"></i>
                            </span>
                            <input
                              type="text"
                              class="form-control"
                              name="othername"
                              id="username"
                              placeholder="Enter Undergradute course"
                              value={underGradDegree}
                              onChange={(e) =>
                                setUnderGradDegree(e.target.value)
                              }
                              required
                            />
                          </div>
                        </div>
                        <div class="form-group">
                          <label for="username">Institution Name</label>
                          <div class="input-group mb-3">
                            <span class="auth-form-icon">
                              <i class="dripicons-user"></i>
                            </span>
                            <input
                              type="text"
                              class="form-control"
                              name="othername"
                              id="username"
                              placeholder="Enter name of institution"
                              value={schoolName}
                              onChange={(e) => setSchoolName(e.target.value)}
                              required
                            />
                          </div>
                        </div>
                        <div class="form-group">
                          <label for="username">Start Date</label>
                          <div class="input-group mb-3">
                            <span class="auth-form-icon">
                              <i class="dripicons-user"></i>
                            </span>
                            <input
                              type="date"
                              class="form-control"
                              name="othername"
                              id="username"
                              value={underGradStart}
                              onChange={(e) =>
                                setUnderGradStart(e.target.value)
                              }
                              required
                            />
                          </div>
                        </div>
                        <div class="form-group">
                          <label for="username">End Date</label>
                          <div class="input-group mb-3">
                            <span class="auth-form-icon">
                              <i class="dripicons-user"></i>
                            </span>
                            <input
                              type="date"
                              class="form-control"
                              name="othername"
                              id="username"
                              value={underGradEnd}
                              onChange={(e) => setUnderGradEnd(e.target.value)}
                              required
                            />
                          </div>
                        </div>
                        <div class="form-group">
                          <label for="username">Class of Degree</label>
                          <div class="input-group mb-3">
                            <span class="auth-form-icon">
                              <i class="dripicons-user"></i>
                            </span>
                            <select
                              class="form-control"
                              value={classDegree}
                              onChange={(e) => setClassDegree(e.target.value)}
                            >
                              <option>--Select--</option>
                              <option value="First Class">First Class</option>
                              <option value="Second Class Upper">
                                Second Class Upper
                              </option>
                              <option value="Second Class Lower">
                                Second Class Lower
                              </option>
                              <option value="Third Class">Third Class</option>
                              <option value="Pass">Pass</option>
                            </select>
                          </div>
                        </div>

                        <div class="form-group">
                          <label for="userpassword">Password</label>
                          <div class="input-group mb-3">
                            <span class="auth-form-icon">
                              <i class="dripicons-lock"></i>
                            </span>
                            <input
                              type="password"
                              class="form-control"
                              name="password"
                              id="userpassword"
                              placeholder="Enter password"
                              value={password}
                              onChange={(e) => setPassword(e.target.value)}
                              required
                            />
                          </div>
                        </div>
                        <div class="form-group">
                          <label for="conf_password">Confirm Password</label>
                          <div class="input-group mb-3">
                            <span class="auth-form-icon">
                              <i class="dripicons-lock-open"></i>
                            </span>
                            <input
                              type="password"
                              class="form-control"
                              name="conf-password"
                              id="conf_password"
                              placeholder="Enter Confirm Password"
                              value={confirmPass}
                              onChange={(e) => setConfirmPass(e.target.value)}
                              required
                            />
                          </div>
                        </div>

                        <div class="form-group mb-0 row d-flex justify-content-center">
                          {loading ? (
                            <div
                              class="spinner-border text-success"
                              role="status"
                            >
                              <span class="sr-only">Loading...</span>
                            </div>
                          ) : (
                            <div class="col-12 mt-2">
                              <button
                                class="btn btn-gradient-success btn-round btn-block waves-effect waves-light"
                                type="submit"
                              >
                                Register <i class="fas fa-sign-in-alt ml-1"></i>
                              </button>
                            </div>
                          )}
                        </div>
                        <ToastContainer />
                      </form>
                    </div>

                    <div class="m-3 text-center text-muted">
                      <p class="">
                        Already have an account ?{" "}
                        <Link to="/apply/login" class="text-success ml-2">
                          Log in
                        </Link>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ApplicationRegister;
