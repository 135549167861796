import { useContext } from "react";
import { useEffect, useState } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import api from "../../../api/api";
import {
  GetApplicantDetails,
  GetPaymentStatus,
} from "../../../api/application";
import Footer from "../../../components/Footer";
import { getCurrentUser } from "../../../components/services/auth/authService";
import AuthContext from "../../../context/AuthContext";
import { subjects } from "../../../common";
import { grading } from "../../../common";
import { ChevronDown, ChevronUp, Trash2 } from "react-feather";
import useDetails from "../../../hooks/useDetails";
import useProspective from "../../../hooks/useProspective";
import useProspectiveInfo from "../../../hooks/useProspectiveInfo";

const ApprovedEduDetails = () => {
  const [utmeScore, setUtmeScore] = useState();
  const [postUtme, setPostUtme] = useState();
  const [degreeChoice1,setDegreeChoice1] = useState();
  const [degreeChoice2,setDegreeChoice2] = useState();
  const [jambNo, setJambNo] = useState();
  const [error, setError] = useState();
  const [department, setDepartment] = useState();

  const [examRegNum, setExamRegNum] = useState("");
  const [examName, setExamName] = useState("");
  const [examDate, setExamDate] = useState("");

  const [examRegNum2, setExamRegNum2] = useState("");
  const [examName2, setExamName2] = useState("");
  const [examDate2, setExamDate2] = useState("");
  const [subjectList, setSubjectList] = useState([]);
  const [subject, setSubject] = useState("");
  const [grade, setGrade] = useState("");

  const [subjectList2, setSubjectList2] = useState([]);
  const [subject2, setSubject2] = useState("");
  const [grade2, setGrade2] = useState("");

  const [toggle, setToggle] = useState(false)

  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const location = useLocation()

  const user = getCurrentUser();

  const token = user?.access

  const {data} = useProspective()
  const {staging:stage} = useProspectiveInfo()

  // const getDetails = async () => {
  //   setLoading(true);
  //   try {
  //     const { data } = await GetApplicantDetails(user.application_id);
  //     console.log("Details", data);
  //     let staging = data.stage;
  //     if (staging === null) {
  //       navigate("/apply/payment");
  //     } else if (staging === null){
  //       navigate("/apply/biodata");
  //     }else if (staging === "biodata") {
  //       navigate("/apply/biodata");
  //     } else if (staging === "nok") {
  //       navigate("/apply/education-details");
  //     } else if (staging === "educational") {
  //       navigate("/apply/preview");
  //     } else{
  //       navigate('/apply')
  //     }
  //     setLoading(false);
  //   } catch (error) {
  //     setLoading(false);
  //   }
  // };

  // const fetchPaymentStatus = async () => {
  //   try {
  //     const { data } = await GetPaymentStatus(user.application_id);
  //     console.log(data, "statuss");
  //     setAdmissionFee(data.application_fee);
  //     setResultFee(data.result_checking_fee);
  //   } catch (error) {}
  // };

  const getFirstSittingCourses = async () => {
    try {
      const response = api.get(`/admissions/applicant/olevel/${user?.admission_id}/1`)
        .then(({data}) => {
          setSubjectList( data.olevel)
          setExamName(data?.exam_name)
          setExamDate(data?.exam_date)
          setExamRegNum(data?.exam_reg_no)
        })
    } catch (error) {
      
    }
  }

  const getSecondSittingCourses = async () => {
    try {
      const response = api.get(`/admissions/applicant/olevel/${user?.admission_id}/2`)
        .then(({data}) => {
          setSubjectList2( data.olevel)
          setExamName2(data?.exam_name)
          setExamDate2(data?.exam_date)
          setExamRegNum2(data?.exam_reg_no)
        })
      
    } catch (error) {
      
    }
  }

  const getDepartments = async () => {
    try {
      const { data } = await api.get("/department/",{
        headers: {
          'Authorization': `Basic ${token}`
        }
      });
      console.log("Department list", data.results);
      setDepartment(data.results);
    } catch (error) {
      console.log(error);
    }
  };

  const updateBio = async (e) => {
    e.preventDefault();
    if (
      !degreeChoice1 ||
      !degreeChoice2 ||
      !utmeScore ||
      !postUtme ||
      !jambNo
    ) {
      return toast.info("Please fill all fields");
    }
    try {
      setLoading(true);
      const res = await api.post(`/admissions/educational/update`, {
        degreeChoice1,
        degreeChoice2,
        utme_score: utmeScore,
        post_utme_score: postUtme,
        jambno: jambNo,
        applicant_id: user.username,
        results_1: subjectList,
        results_2: subjectList2,
        exam_name_1: examName,
        exam_reg_no_1:examRegNum,
        exam_date_1:examDate,
        exam_name_2: examName2,
        exam_reg_no_2:examRegNum2,
        exam_date_2:examDate2,
      });
      console.log(res);
      toast.success("Education details uploaded", successProp);
      // getDetails()
      setLoading(false);
    } catch (error) {
      setError(error);
      setLoading(false);
      toast.error("Updating failed. Try again!", errorProp);
    }
  };

  const handleSubjectSelect = () => {
    if (!subject || !grade) return
    setSubjectList([
      ...subjectList,
      {
        subject: subject,
        grade: grade
      },
    ]);
    setGrade("");
    setSubject("");
  };

  const handleSubjectSelect2 = () => {
    if (!subject2 || !grade2) return
    setSubjectList2([
      ...subjectList2,
      {
        subject: subject2,
        grade: grade2
      },
    ]);
    setGrade2("");
    setSubject2("");
  };

  const handleCourseDelete1 = (id) => {
    let newList = subjectList.filter((item) => item.id !== id);
    setSubjectList(newList);
  };

  const handleCourseDelete2 = (id) => {
    let newList = subjectList2.filter((item) => item.id !== id);
    setSubjectList2(newList);
  };

  useEffect(()=>{
    setTimeout(()=>{},10000)
  },[])

  useEffect(() => {
    getDepartments();
    getFirstSittingCourses()
    getSecondSittingCourses()
  }, []);

  const errorProp = {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  };

  const warnProp = {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  };

  const successProp = {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  };

  if (stage === null) {
    return (
          <Navigate
            to={{
              pathname: `/admission/payment`,
              state: { from: location },
            }}
            replace
          />
        );
  }
  
  if (stage === "submitted") {
    return (
      <Navigate
        to={{
          pathname: `/admission`,
          state: { from: location },
        }}
        replace
      />
    );
  }

  return (
    <div class="page-wrapper">
      {data && <div class="page-content-tab">
        <div class="container-fluid">
          <div class="row">
            <div class="col-sm-12">
              <div class="page-title-box">
                <div class="float-right">
                  <ol class="breadcrumb">
                    {/* <li class="breadcrumb-item"><a href="javascript:void(0);">Metrica</a></li>
                            <li class="breadcrumb-item"><a href="javascript:void(0);">Pages</a></li> */}
                    <li class="breadcrumb-item active">Education details</li>
                  </ol>
                </div>
                <h4 class="page-title">Education details</h4>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="tab-content detail-list" id="pills-tabContent">
                <div class="tab-pane fade show active" id="general_detail">
                  <div class="row">
                    <div class="col-lg-12 col-xl-9 mx-auto">
                      <div class="card">
                        <div class="card-body">
                          <div class="">
                            <form
                              class="form-horizontal form-material mb-0"
                              onSubmit={updateBio}
                            >
                              <div class="form-group row">
                                <div class="col-md-6">
                                  <label>First Choice</label>
                                  <select
                                    class="form-control"
                                    value={data?.degreeChoice1}
                                    onChange={(e) =>
                                      setDegreeChoice1(e.target.value)
                                    }
                                    disabled
                                  >
                                    <option>--Select--</option>
                                    {department?.map(({ id, department }) => (
                                      <option key={id} value={department}>
                                        {department}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                                <div class="col-md-6">
                                  <label>Second Choice</label>
                                  <select
                                    class="form-control"
                                    value={data?.degreeChoice2}
                                    onChange={(e) =>
                                      setDegreeChoice2(e.target.value)
                                    }
                                    disabled
                                  >
                                    <option>--Select--</option>
                                    {department?.map(({ id, department }) => (
                                      <option key={id} value={department}>
                                        {department}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              </div>

                              <div class="form-group row">
                                <div class="col-md-4">
                                  <label>Post UTME score</label>
                                  <input
                                    type="number"
                                    placeholder="UTME score"
                                    class="form-control"
                                    value={data?.post_utme_score}
                                    onChange={(e) =>
                                      setPostUtme(e.target.value)
                                    }
                                    disabled
                                  />
                                </div>
                                <div class="col-md-4">
                                  <label>Jamb number</label>
                                  <input
                                    type="text"
                                    placeholder="Jamb number"
                                    class="form-control"
                                    value={jambNo}
                                    onChange={(e) => setJambNo(e.target.value)}
                                    disabled
                                  />
                                </div>
                                <div class="col-md-4">
                                  <label>UTME score</label>
                                  <input
                                    type="text"
                                    placeholder="UTME score"
                                    class="form-control"
                                    value={data?.utme_score}
                                    onChange={(e) =>
                                      setUtmeScore(e.target.value)
                                    }
                                    disabled
                                  />
                                </div>
                              </div>
                              <div class="form-group row">
                                <div className="col-md-4">
                              <label><b>Olevel</b>(1st sitting)</label>
                                </div>
                              </div>
                              <div class="form-group row">
                                <div className="col-md-4">
                                  <label>Examination name</label>
                                  <select
                                    class="form-control"
                                    value={examName}
                                    onChange={(e) =>
                                      setExamName(e.target.value)
                                    }
                                    disabled
                                  >
                                    <option>--Select--</option>
                                    <option value="waec">WAEC</option>
                                    <option value="neco">NECO</option>
                                  </select>
                                </div>
                                <div class="col-md-4">
                                  <label>Examination reg number</label>
                                  <input
                                    class="form-control"
                                    value={examRegNum}
                                    onChange={(e) =>
                                      setExamRegNum(e.target.value)
                                    }
                                    disabled
                                    placeholder="Enter examination reg number"
                                  ></input>
                                </div>
                                <div class="col-md-4">
                                  <label>Examination Date</label>
                                  <input
                                    class="form-control"
                                    value={examDate}
                                    onChange={(e) =>
                                      setExamDate(e.target.value)
                                    }
                                    disabled
                                    type="date"
                                  ></input>
                                </div>
                              </div>

                              <p className="text-muted">Select olevel grades (1st sitting)</p>

                              <div class="form-group row">
                                <div class="col-md-6">
                                  <select
                                    class="form-control"
                                    value={subject}
                                    onChange={(e) => setSubject(e.target.value)}
                                    disabled
                                  >
                                    <option>--Select subject--</option>
                                    {subjects?.map((subject) => (
                                      <option
                                        key={subject.index}
                                        value={subject.name}
                                      >
                                        {subject.name}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                                <div class="col-md-3">
                                  <select
                                    class="form-control"
                                    value={grade}
                                    onChange={(e) => setGrade(e.target.value)}
                                    disabled
                                  >
                                    <option>--Select Grade--</option>
                                    {grading?.map((item) => (
                                      <option
                                        key={item.index}
                                        value={item.name}
                                      >
                                        {item.name}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                                <div class="col-md-3">
                                  <button
                                    type="button"
                                    onClick={handleSubjectSelect}
                                    className="btn btn-danger"
                                  >
                                    Select Subject
                                  </button>
                                </div>
                              </div>
                              {subjectList.length? 
                              <table>
                                <thead>
                                  <th>Subject</th>
                                  <th>Grade</th>
                                  <th></th>
                                </thead>
                                {subjectList?.map((list) => (
                                  <tbody>
                                    <td>{list.subject}</td>
                                    <td>{list.grade}</td>
                                    <td>
                                      <Trash2 className="waves-effect align-self-center icon-dual-pink icon-lg" />
                                    </td>
                                  </tbody>
                                ))}
                              </table>
                              : ""
                            }
                              <br/>
                              <div class="form-group row">
                              <div className="col-md-4">
                              <label><b>2nd setting</b>(optional)<span onClick={()=>setToggle(!toggle)}>{toggle? <ChevronDown/>:<ChevronUp/>}</span></label>
                              </div>
                              </div>
                              {toggle? 
                                <>
                                <div class="form-group row">
                                  <div className="col-md-4">
                                    <label>Examination name</label>
                                    <select
                                      class="form-control"
                                      value={examName2}
                                      onChange={(e) =>
                                        setExamName2(e.target.value)
                                      }
                                      disabled
                                    >
                                      <option>--Select--</option>
                                      <option value="waec">WAEC</option>
                                      <option value="neco">NECO</option>
                                    </select>
                                  </div>
                                  <div class="col-md-4">
                                    <label>Examination reg number</label>
                                    <input
                                      class="form-control"
                                      value={examRegNum2}
                                      onChange={(e) =>
                                        setExamRegNum2(e.target.value)
                                      }
                                      disabled
                                      placeholder="Enter examination reg number"
                                    ></input>
                                  </div>
                                  <div class="col-md-4">
                                    <label>Examination Date</label>
                                    <input
                                      class="form-control"
                                      value={examDate2}
                                      onChange={(e) =>
                                        setExamDate2(e.target.value)
                                      }
                                      disabled
                                      type="date"
                                    ></input>
                                  </div>
                                </div>
  
                                <p className="text-muted">Select olevel grades (2nd sitting)</p>
  
                                <div class="form-group row">
                                  <div class="col-md-6">
                                    <select
                                      class="form-control"
                                      value={subject2}
                                      onChange={(e) => setSubject2(e.target.value)}
                                      disabled
                                    >
                                      <option>--Select subject--</option>
                                      {subjects?.map((subject) => (
                                        <option
                                          key={subject.index}
                                          value={subject.name}
                                        >
                                          {subject.name}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                  <div class="col-md-3">
                                    <select
                                      class="form-control"
                                      value={grade2}
                                      onChange={(e) => setGrade2(e.target.value)}
                                      disabled
                                    >
                                      <option>--Select Grade--</option>
                                      {grading?.map((item) => (
                                        <option
                                          key={item.index}
                                          value={item.name}
                                        >
                                          {item.name}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                  <div class="col-md-3">
                                    <button
                                      type="button"
                                      onClick={handleSubjectSelect2}
                                      className="btn btn-danger"
                                    >
                                      Select Subject
                                    </button>
                                  </div>
                                </div>
                                {subjectList2.length ? 
                                <table>
                                  <thead>
                                    <th>Subject</th>
                                    <th>Grade</th>
                                    <th></th>
                                  </thead>
                                  {subjectList2?.map((list) => (
                                    <tbody>
                                      <td>{list.subject}</td>
                                      <td>{list.grade}</td>
                                      <td>
                                        <Trash2 className="waves-effect align-self-center icon-dual-pink icon-lg" />
                                      </td>
                                    </tbody>
                                  ))}
                                </table>
                                : ""
                              } 
                                </>
                                : ''
                            }
                            <div class="form-group">
                            <button
                              class="btn btn-gradient-primary btn-sm px-4 mt-3 float-right mb-0"
                              type="submit"
                              disabled
                            >
                              Update Details
                            </button>
                          </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>}
    </div>
  );
};

export default ApprovedEduDetails;
