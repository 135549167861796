import { useEffect } from "react";
import { useState } from "react";
import {
  GetApplicationFee,
  GetPaymentStatus,
  GetProspectivePayment,
  InitiateAcceptancePayment,
  InitiateApplicationPayment,
  InitiateProspectiveResultCheckingPayment,
  InitiateResultCheckingPayment,
  InitiatemedicalfeePayment,
  InitiateprocessingfeePayment,
  InitiatecertificatefeePayment
} from "../../../api/application";
import Footer from "../../../components/Footer";
import { getCurrentUser } from "../../../components/services/auth/authService";
import moment from "moment";
import { toast } from "react-toastify";
import ApprovedPaymentComponent from "../component/ApprovedPaymentComponent";
import ApprovedmedicalPaymentComponent from "../component/ApprovedmedicalPaymentComponent";
import ApprovedprocessingPaymentComponent from "../component/ApprovedprocessingPaymentComponent";
import ApprovedResultComponent from "../component/ApprovedResultComponent";
import useProspectivePayment from "../../../hooks/useProspectivePayment";
import api from "../../../api/api";
import { numberWithCommas } from "../../../common";
import useAdmission from "../../../hooks/useAdmission";

const ApprovedPayment = () => {
  const user = getCurrentUser();
  const [data, setData] = useState([]);
  const [acceptanceFee, setAcceptanceFee] = useState();
  const [certificateFee, setCertificateFee] = useState();
  const [medicalfee, setMedicalFee] = useState();
  const [processingfee, setProcessing_Fee] = useState();
  const [resultFee, setResultFee] = useState();
  const [resultFee2, setResultFee2] = useState();
  const [secondSitting, setSecondSitting] = useState();
  const [loading, setLoading] = useState(false);
  const [btnText, setBtnText] = useState("Proceed to payment");

  const {
    data: paymentData,
    acceptanceStatus,
    resultStatus,
    medical_fee,
    processing_fee,
    certificateStatus
  } = useProspectivePayment();

  const { data: details } = useAdmission();

  const getPaymentDetails = async () => {
    try {
      const { data } = await GetApplicationFee();
      console.log(data);
      setAcceptanceFee(data[2]);
      setResultFee(data[4]);
      setResultFee2(data[5]);
      setMedicalFee(data[5]);
      setProcessing_Fee(data[8]);
      setCertificateFee(data[10]);
    } catch (error) {}
  };

  const payAcceptanceFee = async () => {
    try {
      setBtnText("Initializing...");
      setLoading(true);
      const { data } = await InitiateAcceptancePayment({
        admission_no: user.admission_id,
      });
      console.log("payment oh", data);
      const { status, authorization_url, reference } = data;
      if (status && status === "success") {
        toast.info("Payment already made");
        setLoading(false);
        setBtnText("Proceed to payment");
      } else {
        toast.success("Initiating payment");
        window.location.replace(authorization_url);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      toast.error("Initiating payment failed!");
      setBtnText("Initiating payment failed!");
    }
  };

  const payCertificateFee = async () => {
    try {
      setBtnText("Initializing...");
      setLoading(true);
      const { data } = await InitiatecertificatefeePayment({
        admission_no: user.admission_id,
      });
      console.log("payment oh", data);
      const { status, authorization_url, reference } = data;
      if (status && status === "success") {
        toast.info("Payment already made");
        setLoading(false);
        setBtnText("Proceed to payment");
      } else {
        toast.success("Initiating payment");
        window.location.replace(authorization_url);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      toast.error("Initiating payment failed!");
      setBtnText("Initiating payment failed!");
    }
  };

  const payMedical_fee = async () => {
    try {
      setBtnText("Initializing...");
      setLoading(true);
      const { data } = await InitiatemedicalfeePayment({
        admission_no: user.admission_id,
      });
      console.log("payment oh", data);
      const { status, authorization_url, reference } = data;
      if (status && status === "success") {
        toast.info("Payment already made");
        setLoading(false);
        setBtnText("Proceed to payment");
      } else {
        toast.success("Initiating payment");
        window.location.replace(authorization_url);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      toast.error("Initiating payment failed!");
      setBtnText("Initiating payment failed!");
    }
  };

  const payprocessing_fee = async () => {
    try {
      setBtnText("Initializing...");
      setLoading(true);
      const { data } = await InitiateprocessingfeePayment({
        admission_no: user.admission_id,
      });
      console.log("payment oh", data);
      const { status, authorization_url, reference } = data;
      if (status && status === "success") {
        toast.info("Payment already made");
        setLoading(false);
        setBtnText("Proceed to payment");
      } else {
        toast.success("Initiating payment");
        window.location.replace(authorization_url);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      toast.error("Initiating payment failed!");
      setBtnText("Initiating payment failed!");
    }
  };

  const payResultCheckFee = async () => {
    try {
      setLoading(true);
      const { data } = await InitiateProspectiveResultCheckingPayment({
        admission_no: user.admission_id,
      });
      console.log("payment oh", data);
      const { status, authorization_url, reference } = data;
      if (status && status === "success") {
        toast.info("Payment already made");
        setLoading(false);
      } else {
        toast.success("Initiating payment");
        window.location.replace(authorization_url);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      toast.error("Initiating payment failed!");
    }
  };

  const getSecondSittingCourses = async () => {
    try {
      const response = api
        .get(`/admissions/applicant/olevel/${user?.admission_id}/2`)
        .then(({ data }) => {
          setSecondSitting(data.olevel);
        });
    } catch (error) {}
  };

  useEffect(() => {
    getPaymentDetails();
    getSecondSittingCourses();
  }, []);
  return (
    <>
      <div class="page-wrapper">
        {paymentData && (
          <div class="page-content-tab">
            <div class="container-fluid">
              <div class="row">
                <div class="col-sm-12">
                  <div class="page-title-box">
                    <div class="float-right">
                      <ol class="breadcrumb">
                        {/* <li class="breadcrumb-item"><a href="javascript:void(0);">Metrica</a></li>
                            <li class="breadcrumb-item"><a href="javascript:void(0);">Pages</a></li> */}
                        <li class="breadcrumb-item active">Payment</li>
                      </ol>
                    </div>
                    <h4 class="page-title">Payment</h4>
                  </div>
                 
                  <div>
                    <div class="card p-5" style={{ padding: "10px" }}>
                      <div>
                        {/* <p class="business">{acceptanceFee?.name && "Acceptance Fee payment"}</p> */}
                      </div>
                      <div class="col-md-6">
                        <label>Application Number</label>
                        <h6>{details?.username}</h6>
                      </div>
                      <div class="col-md-6">
                        <label>Payer</label>
                        <h6>
                          {details?.surname} {details?.othername}
                        </h6>
                      </div>
                      <div class="col-md-6">
                        <label>Email Address</label>
                        <h6>{details?.email}</h6>
                      </div>
                      <div class="col-md-6">
                        <label>Session</label>
                        <h6>{details?.entrySession}</h6>
                      </div>

                      <hr className="border border-gray"/>
                      <table className="table">
                        <thead className="thead-dark">
                          <th>S/N</th>
                          <th>Payment Name</th>
                          <th>Amount</th>
                          <th>Action</th>
                        </thead>
                        <tbody>
                          <tr>
                            <th>1</th>
                            <th>{processingfee?.name}</th>
                            <th>
                              <h3>
                                {processingfee &&
                                  numberWithCommas("₦" + processingfee?.amount)}
                              </h3>
                            </th>
                            <th>
                              {processing_fee?.payment_status === "success" ? (
                                <span class="badge badge-success">Paid</span>
                              ) : (
                                <button className="btn dspg-danger" onClick={payprocessing_fee} disabled={loading}>
                                  Proceed to Payment
                                </button>
                              )}
                            </th>
                          </tr>
                          <tr>
                            <th>2</th>
                            <th>{acceptanceFee?.name}</th>
                            <th>
                              <h3>
                                {acceptanceFee &&
                                  numberWithCommas("₦" + acceptanceFee?.amount)}
                              </h3>
                            </th>
                            <th>
                              {acceptanceStatus?.payment_status ===
                              "success" ? (
                                <span class="badge badge-success">Paid</span>
                              ) : (
                                <button className="btn dspg-danger" onClick={payAcceptanceFee} disabled={loading}>
                                  Proceed to Payment
                                </button>
                              )}
                            </th>
                          </tr>
                          <tr>
                            <th>3</th>
                            <th>{certificateFee?.name}</th>
                            <th>
                              <h3>
                                {certificateFee &&
                                  numberWithCommas("₦" + certificateFee?.amount)}
                              </h3>
                            </th>
                            <th>
                              {certificateStatus?.payment_status ===
                                "success" ? (
                                <span class="badge badge-success">Paid</span>
                              ) : (
                                  <button className="btn dspg-danger" onClick={payCertificateFee} disabled={loading}>
                                  Proceed to Payment
                                </button>
                              )}
                            </th>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Footer />
          </div>
        )}
      </div>
    </>
  );
};

export default ApprovedPayment;
