import { async } from "q";
import React, { useEffect, useState, useRef } from "react";
import QRCode from "react-qr-code";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import {
  GetApplicantDetails,
  GetApplicantOlevel,
  GetPaymentStatus,
} from "../../../api/application";
import logo from "../../../assets/images/coewarri.png";
import logo2 from "../../../assets/images/IAUE.png";
import { getCurrentUser } from "../../../components/services/auth/authService";
import { useReactToPrint } from "react-to-print";
import api from "../../../api/api";
import { toast } from "react-toastify";
import useDetails from "../../../hooks/useDetails";
import avatar from "../../../assets/images/user.png";
import CustomModal from "../components/CustomModal";

const ApplicationPreview = () => {
  const user = getCurrentUser();
  const [passport, setPassport] = useState();
  const [olevel, setOlevel] = useState();
  const [olevel2, setOlevel2] = useState();
  const [details1, setDetails1] = useState();
  const [details2, setDetails2] = useState();
  const [loading, setLoading] = useState(false);
  const [submissionStatus, setSubmissionStatus] = useState("");
  const [admissionFee, setAdmissionFee] = useState();
  const [resultFee, setResultFee] = useState();
  const [openModal, setOpenModal] = useState(false);
  const location = useLocation();

  const { data: student, staging: stage } = useDetails();

  const fetchPaymentStatus = async () => {
    try {
      const { data } = await GetPaymentStatus(user.application_id);
      console.log(data, "statuss");
      setAdmissionFee(data.application_fee);
      setResultFee(data.result_checking_fee);
    } catch (error) {}
  };

  const navigate = useNavigate();

  const printPaymentReceipt = () => {
    window.print();
  };

  const componentRef = useRef();

  const pageStyle = `
  @page {
    size: 210mm 297mm;
    margin: 2rem 8em;
  }
`;
  const printAcceptanceLetter = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "Application file",
    onAfterPrint: () => console.log("Application letter printed"),
    pageStyle: pageStyle,
  });

  // const getOlevel = async () => {
  //   try {
  //     const data = await GetApplicantOlevel(user.application_id);
  //     console.log("Olevel list", data.olevel);
  //     setOlevel(data.olevel);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const getFirstSittingCourses = async () => {
    try {
      const response = api
        .get(`/admissions/applicant/olevel/${user?.application_id}/1`)
        .then(({ data }) => {
          setOlevel(data.olevel);
          setDetails1(data);
          console.log(data);
        });
    } catch (error) {}
  };

  const getSecondSittingCourses = async () => {
    try {
      const response = api
        .get(`/admissions/applicant/olevel/${user?.application_id}/2`)
        .then(({ data }) => {
          setOlevel2(data.olevel);
          setDetails2(data);
        });
    } catch (error) {}
  };

  const onImageError = (e) => {
    e.target.src = avatar;
  };

  const submitApplication = async () => {
    setLoading(true);
    try {
      const { data } = await api.put(
        `/admissions/submit/${user.application_id}`
      );
      console.log("Submission Response", data);
      toast.success("Application submitted");
      setLoading(false);
      window.location.reload();
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    // getOlevel();
    getFirstSittingCourses();
    getSecondSittingCourses();
    fetchPaymentStatus();
  }, []);

  if (stage === null) {
    return (
      <Navigate
        to={{
          pathname: `/apply/payment`,
          state: { from: location },
        }}
        replace
      />
    );
  }
  return (
    <>
      {student && (
        <div className="receipt" style={{ marginTop: "3rem" }}>
          <div className="contents" ref={componentRef}>
            <div className="receipt-header">
              <div className="school-title">
                <img src={logo} alt="COEWARRI" className="logo" />
                <h3 className="name">College of Education, Warri</h3>
              </div>
              <h6 className="text-center">In Affiliation with</h6>
              <div className="school-title">
                <img src={logo2} alt="IAUE" className="logo" />
                <h3 className="name">
                  Ignatius Ajuru University of Education, Port Harcourt
                </h3>
              </div>
              <div style={{ borderBottom: "2px solid #000" }}></div>
              <h4 className="subheader">
                PG ADMISSION SCREENING EXERCISE {student?.entrySession} <br />
                <span>Application Record</span>
              </h4>
            </div>
            <div className="receipt-body">
              <div className="body-header">
                <div className="passport">
                  <img
                    src={student?.photo}
                    onError={onImageError}
                    alt="Passport"
                  />
                </div>
                <div className="user-details">
                  <div>
                    <p className="user-details-option">Application Number:</p>
                    <p className="user-details-info">{student?.username}</p>
                  </div>
                  <div>
                    <p className="user-details-option">Surname:</p>
                    <p className="user-details-info">{student?.surname}</p>
                  </div>
                  <div>
                    <p className="user-details-option">Othername(s):</p>
                    <p className="user-details-info">{student?.othername}</p>
                  </div>
                </div>
                <div className="qr-code">
                  {student && <QRCode value={student?.username} size={90} />}
                </div>
              </div>
              <hr />
              <div className="body-info">
                <div>
                  <p className="user-details-option">Date of Birth:</p>
                  <p className="user-details-info">
                    {student?.dob + "/" + student?.mob + "/" + student?.yob}
                  </p>
                </div>
                <div>
                  <p className="user-details-option">Gender:</p>
                  <p className="user-details-info">{student?.gender}</p>
                </div>
                <div>
                  <p className="user-details-option">Email Address:</p>
                  <p className="user-details-info"> {student?.email}</p>
                </div>
                <div>
                  <p className="user-details-option">Nationality:</p>
                  <p className="user-details-info">{student?.country_origin}</p>
                </div>
                <div>
                  <p className="user-details-option">State/LGA:</p>
                  <p className="user-details-info">
                    {student?.state_origin}/{student?.lga_origin}
                  </p>
                </div>
                <br />
                <h4>
                  <b>Programmes/Courses Desired</b>
                </h4>
                <div>
                  <p className="user-details-option">
                    1st Choice Course of Study:
                  </p>
                  <p className="user-details-info">{student?.degreeChoice1}</p>
                </div>
                <div>
                  <p className="user-details-option">
                    2nd Choice Course of Study:
                  </p>
                  <p className="user-details-info">{student?.degreeChoice2}</p>
                </div>
                <br />
                <h4>
                  <b>Higher Qualifications</b>
                </h4>
                <div>
                  <p className="user-details-option">Name of Institution:</p>
                  <p className="user-details-info">{student?.school_name}</p>
                </div>
                <div>
                  <p className="user-details-option">Degree:</p>
                  <p className="user-details-info">
                    {student?.undergrad_course}
                  </p>
                </div>
                <div>
                  <p className="user-details-option">Class of Degree:</p>
                  <p className="user-details-info">
                    {student?.class_of_degree}
                  </p>
                </div>
                <div>
                  <p className="user-details-option">Graduation Year:</p>
                  <p className="user-details-info">
                    {student?.undergrad_year_of_grad}
                  </p>
                </div>
                <div>
                  <p className="user-details-option">NYSC:</p>
                  <p className="user-details-info">{student?.nysc}</p>
                </div>
                <br />
                <h4>
                  <b>First sitting Results</b>
                </h4>
                <div>
                  <p className="user-details-option">Examination Name:</p>
                  <p className="user-details-info">{details1?.exam_name}</p>
                </div>
                <div>
                  <p className="user-details-option">Examination Reg Number:</p>
                  <p className="user-details-info">{details1?.exam_reg_no}</p>
                </div>
                <div>
                  <p className="user-details-option">Examination Date:</p>
                  <p className="user-details-info">{details1?.exam_date}</p>
                </div>
                <div>
                  <table>
                    <th>Subject</th>
                    <th>Grade</th>
                    <tbody>
                      {olevel?.map((item) => (
                        <tr key={item.id}>
                          <td>{item.subject}</td>
                          <td>{item.grade}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                {/* Second sitting*/}
                <br />
                {olevel2?.length && (
                  <>
                    <h4>
                      <b>Second Sitting Results</b>
                    </h4>
                    <div>
                      <p className="user-details-option">Examination Name:</p>
                      <h3 className="user-details-info">
                        {details2?.exam_name}
                      </h3>
                    </div>
                    <div>
                      <p className="user-details-option">
                        Examination Reg Number:
                      </p>
                      <h3 className="user-details-info">
                        {details2?.exam_reg_no}
                      </h3>
                    </div>
                    <div>
                      <p className="user-details-option">Examination Date:</p>
                      <h3 className="user-details-info">
                        {details2?.exam_date}
                      </h3>
                    </div>
                    <div>
                      <table>
                        <th>Subject</th>
                        <th>Grade</th>
                        <tbody>
                          {olevel2?.map((item) => (
                            <tr key={item.id}>
                              <td>{item.subject}</td>
                              <td>{item.grade}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </>
                )}
              </div>
            </div>

            <div className="print-button">
              {stage === "completed" ||
              stage === "rejected" ||
              stage === "approved" ? (
                <button className="btn-primary" onClick={printAcceptanceLetter}>
                  Print Application
                </button>
              ) : (
                <>
                  <button
                    className="btn-primary"
                    data-toggle="modal"
                    data-target="#exampleModalCenter"
                  >
                    Submit Application
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      )}
      )
      <CustomModal
        submitApplication={submitApplication}
        loading={loading}
        modalTitle="Are you sure?"
        modalBody="Make sure you have provided all necessary information before proceeding. You cannot access them after submission except you contact the school's Admin."
      />
    </>
  );
};

export default ApplicationPreview;
