import axios from "axios";
import {auth_token, URL} from '../utils/appParams'
import { removeUser } from "../components/services/auth/authService";


const api = axios.create({
  baseURL: URL,
  headers: {
    Accept: "application/json"
  },
});

api.interceptors.request.use(
	config => {
		if (auth_token()) {
			config.headers['Authorization'] = `Bearer ${auth_token()}`
		}

		return config
	},
	error => Promise.reject(error)
);

api.interceptors.response.use(
	(response) => response,
	(error) => {
	  if (error.response.status === 401) {
		logOut()
	  }
	  return Promise.reject(error);
	}
  );

  const logOut = () => {
    removeUser();
  };

export default api;