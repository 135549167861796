import { useEffect } from "react";
import { useState } from "react";
import { GetPaymentStatus, InitiateApplicationPayment, InitiateResultCheckingPayment, VerifyApplicationPayment } from "../../../api/application";
import Footer from "../../../components/Footer";
import { getCurrentUser } from "../../../components/services/auth/authService";
import moment from "moment";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const ApplicationPaymentInvoice = () => {
  const user = getCurrentUser();
  const [data, setData] = useState([]);
  const [admissionFee, setAdmissionFee] = useState();
  const [resultFee, setResultFee] = useState();
  const [loading, setLoading] = useState(false)

  const navigate = useNavigate()

  const getPayments = async () => {
    try {
      const { data } = await GetPaymentStatus(user.application_id);
      console.log(data, "Invoice");
      setData(data);
      setAdmissionFee(data.application_fee);
      setResultFee(data.result_checking_fee);
    } catch (error) {}
  };

  const payApplicationFee = async () => {
    try {
      setLoading(true);
      const { data } = await InitiateApplicationPayment({
        application_id: user.application_id,
      });
      console.log("payment oh", data);
      const {status, authorization_url, reference} = data
      if (status && status === "success") {
        toast.info("Payment already made")
        setLoading(false)
      } else {
        toast.success("Initiating payment");
        window.location.replace(authorization_url);
        setLoading(false);

      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      toast.error("Initiating payment failed!");
    }
  };

  const payResultCheckFee = async () => {
    try {
      setLoading(true);
      const { data } = await InitiateResultCheckingPayment({
        application_id: user.application_id,
      });
      console.log("payment oh", data);
      const {status, authorization_url, reference} = data
      if (status && status === "success") {
        toast.info("Payment already made")
        setLoading(false)
      } else {
        toast.success("Initiating payment");
        window.location.replace(authorization_url);
        setLoading(false);

      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      toast.error("Initiating payment failed!");
    }
  };

  const paymentRequery = async (ref) => {
    if (!ref) return alert('No Payment Reference Found')
    try {
        setLoading(true)
        toast.loading("Requering in Progress")
        const response = await VerifyApplicationPayment(ref);
        setLoading(false)
        window.location.reload()
    } catch (error) {
      toast.error('Verification Failed')
      setLoading(false)
    }
}

  useEffect(() => {
    getPayments();
  }, []);
  return (
    <>
      <div class="page-wrapper">
        {data && (
          <div class="page-content-tab">
            <div class="container-fluid">
              <div class="row">
                <div class="col-sm-12">
                  <div class="page-title-box">
                    <div class="float-right">
                      <ol class="breadcrumb">
                        {/* <li class="breadcrumb-item"><a href="javascript:void(0);">Metrica</a></li>
                            <li class="breadcrumb-item"><a href="javascript:void(0);">Pages</a></li> */}
                        <li class="breadcrumb-item active">Invoice</li>
                      </ol>
                    </div>
                    <h4 class="page-title">Payment Invoice</h4>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <div class="tab-content detail-list" id="pills-tabContent">
                    <div class="tab-pane fade show active" id="general_detail">
                      <div class="row">
                        <div class="col-lg-12 col-xl-9 mx-auto">
                          <div class="card">
                            <div class="card-body">
                                {(admissionFee) && 
                              <div class="table-responsive">
                                <table class="table mb-0">
                                  <thead class="thead-light">
                                    <tr>
                                      <th>#</th>
                                      <th>Payment</th>
                                      <th>Reference</th>
                                      <th>Generated Date</th>
                                      <th>Payment Status</th>
                                      <th>#</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <th scope="row">1</th>
                                      <td>Application Fee</td>
                                      <td>{admissionFee?.payment_ref}</td>
                                      <td>
                                        {moment(
                                          admissionFee?.generated_date
                                        ).format("DD/MM/YYYY hh:mm")}
                                      </td>
                                      <td>
                                        <span
                                          class={`badge badge-boxed  badge-soft-${
                                            admissionFee?.payment_status ===
                                            "success"
                                              ? "success"
                                              : "warning"
                                          }`}
                                        >
                                          {admissionFee?.payment_status === "success" ? "Success" : "Pending"}
                                        </span>
                                      </td>
                                      <td>{(admissionFee?.payment_status !== 'success' && admissionFee?.paystack_ref)? <button className="btn btn-default" disabled={loading} onClick={() => paymentRequery(admissionFee?.paystack_ref)}>Requery</button>:''}</td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                                }
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Footer />
          </div>
        )}
      </div>
    </>
  );
};

export default ApplicationPaymentInvoice;
