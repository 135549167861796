import { useEffect, useState } from "react"
import { GetProspectiveDetails, GetProspectivePayment } from "../api/application";
import { getCurrentUser } from "../components/services/auth/authService";

const useProspectivePayment = () => {
    const [data, setData] = useState()
    const [staging, setStaging] = useState()
    const [acceptanceStatus, setAcceptanceStatus] = useState()
    const [certificateStatus, setCertificateStatus] = useState()
    const [resultStatus, setResultStatus] = useState()
    const [admission_letterstatus, setadmission_letter] = useState()
    const [medical_feeStatus, setmedical_feeStatus] = useState()
    const [processingFee, setProcessingFee] = useState()
    const [courseChangeStatus, setCourseChangeStatus] = useState()
    const [loading, setLoading] = useState(false);
    const user = getCurrentUser();

    useEffect(() => {
        const getAdmission = async () => {
            setLoading(true);
            try {
              const { data } = await GetProspectivePayment(user.admission_id);
              console.log("Prospective Payments", data);
              setData(data)
              setAcceptanceStatus(data?.acceptance_fee)
              setResultStatus(data?.result_checking_fee)
              setmedical_feeStatus(data?.medical_fee)
              setadmission_letter(data?.admission_letter)
              setProcessingFee(data?.processing_fee)
              setCourseChangeStatus(data?.change_of_course)
              setCertificateStatus(data?.certificate_fee)
              setLoading(false);
            } catch (error) {
              setLoading(false);
            }
          };
          getAdmission()
    }, [])

    return {data, acceptanceStatus, resultStatus, courseChangeStatus, medical_feeStatus, processingFee, admission_letterstatus}
}

export default useProspectivePayment